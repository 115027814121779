<template>
  <div style="height:100%">
    <v-row style="height:100%">
      <v-col cols="12" xs="12" sm="6" md="5" lg="4">
        <v-card :loading="isLoading">
          <div class="d-flex flex-no-wrap">
            <v-avatar class="ma-3" size="125" tile>
              <v-img contain :src="fileUrl(uInfo.user_avatar)"></v-img>
            </v-avatar>

            <div>
              <v-card-title class="headline">{{uInfo.user_login}}</v-card-title>
              <v-card-subtitle>{{uInfo.user_fullname}}</v-card-subtitle>
              <v-chip
                class="ma-2"
                :color="uInfo.user_status|userStatusColor"
                label
                text-color="white"
              >{{uInfo.user_status|userStatusText}}</v-chip>
            </div>
          </div>
          <div>
            <div style="padding-left:20px">
              <v-btn @click="changeAvtClick()" outlined small color="primary">
                <v-icon left x-small>fas fa-pencil-alt</v-icon>Đổi ảnh
              </v-btn>&nbsp;
              <template v-if="isAdmin">
                <v-btn

                outlined
                small
                @click="uInfo.user_status=0"
                v-if="uInfo.user_status==1"
                color="red"
              >
                <v-icon left x-small>fas fa-ban</v-icon>Dừng tài khoản
              </v-btn>
              <v-btn @click="uInfo.user_status=1" v-else outlined small color="green">
                <v-icon left x-small>fas fa-check</v-icon>Kích hoạt tài khoản
              </v-btn>
              </template>

            </div>
            <div style="padding-left:20px;padding-right:20px" v-show="avatarFileSelected">
              <v-file-input ref="file-avatar-input" dense v-model="avatarFileSelected"></v-file-input>
            </div>

            <v-divider></v-divider>

            <div style="padding-left:15px">
              <span style="    font-size: 14px;
    font-weight: bold;
    color: #616161;">Quyền:</span>
              <v-chip
                class="ma-2"
                :color="uInfo.user_role|roleStatusColor"
                label
                text-color="white"
              >{{uInfo.user_role|roleStatusText}}</v-chip>
              <v-menu v-if="isAdmin" top offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined small color="blue" v-bind="attrs" v-on="on">
                    <v-icon x-small>fas fa-pencil-alt</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-for="(item, index) in userRoleMap" :key="index">
                    <v-list-item-title
                      @click="uInfo.user_role=index"
                      class="menu-item"
                    >{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div style="padding-left:15px" v-if="uInfo.user_role!=9">
              <span
                style="    font-size: 14px;
    font-weight: bold;
    color: #616161;"
              >Tại cửa hàng:&nbsp;</span>
              <v-menu v-if="isAdmin" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" outlined small color="primary">
                    <v-icon left x-small>fas fa-plus</v-icon>Thêm
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in stores" :key="index">
                    <v-list-item-title
                      @click="addStoreToUser(item)"
                      class="menu-item"
                    >{{ item.store_name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div>
                <v-list>
                  <v-list-item v-for="(item, index) in uInfo.stores" :key="index">
                    <!-- <v-list-item-avatar>
                      <v-img :src="item.avatar"></v-img>
                    </v-list-item-avatar>-->
                    <v-list-item-content>
                      <v-list-item-title v-text="item.store_name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="isAdmin">
                      <v-btn  outlined small color="error" @click="uInfo.stores.splice(index,1)">
                        <v-icon x-small>fas fa-times</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <div style="padding:15px">
            <span
              style="    font-size: 14px;
    font-weight: bold;
    color: #616161;"
            >Ảnh hồ sơ:&nbsp;</span>
            <v-btn @click="addProfileFileClick()" outlined small color="primary">
              <v-icon left x-small>fas fa-plus</v-icon>Thêm ảnh
            </v-btn>&nbsp;
            <v-btn @click="viewImage()" outlined small color="teal">
              <v-icon left x-small>fas fa-desktop</v-icon>Xem ảnh
            </v-btn>&nbsp;
            <div v-show="profileFilesSelected">
              <v-file-input ref="file-profile-input" multiple dense v-model="profileFilesSelected"></v-file-input>
            </div>
            <v-row v-if="uInfo.user_profile_imgs">
              <v-col
                v-for="(img,index) in uInfo.user_profile_imgs"
                :key="img"
                class="d-flex child-flex"
                cols="4"
              >
                <v-card flat tile class="d-flex">
                  <v-img :src="fileUrl(img)" aspect-ratio="1" class="grey lighten-2">
                    <v-btn
                      icon
                      x-small
                      @click="uInfo.user_profile_imgs.splice(index,1)"
                      color="red"
                    >
                      <v-icon>fas fa-minus-circle</v-icon>
                    </v-btn>
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row align="center" justify="end">
              <v-btn
                style="
    margin-right: 25px;
"
                depressed
                color="success"
                :loading="isLoading"
                @click="save()"
              >Lưu lại</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="7" lg="8">
        <v-card :loading="isLoading">
          <div>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline">Thông tin cá nhân</v-list-item-title>
                <v-list-item-subtitle>Cập nhật thông tin cá nhân</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
          <v-divider></v-divider>
          <div style="padding:20px">
            <v-form ref="form">
              <v-text-field :readonly="!isAdmin" dense v-model="uInfo.user_login" label="Tên đăng nhập" outlined></v-text-field>
              <v-text-field dense v-model="uInfo.user_fullname" label="Tên đầy đủ" outlined></v-text-field>
              <v-text-field
                disabled
                value="*****"
                readonly
                dense
                label="Mật khẩu"
                type="password"
                outlined
              >
                <template v-slot:append-outer>
                  <v-menu v-if="isAdmin"
                    v-model="changePassPopoverShow"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn outlined small color="blue" v-bind="attrs" v-on="on">
                        <v-icon left x-small>fas fa-sync</v-icon>Reset
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title>Nhập mật khẩu mới</v-card-title>

                      <v-card-subtitle>Bạn không thể hoàn tác, sau khi đã thay đổi!!!</v-card-subtitle>
                        <v-text-field style="margin:0px 20px 0px 20px" dense=""
            label="Mật khẩu mới" v-model="newPassword"
            outlined
          ></v-text-field>
                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn text @click="changePassPopoverShow = false">Hủy</v-btn>
                        <v-btn color="primary" text @click="resetPasswordClick()">Đổi mật khẩu</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-menu v-else
                    v-model="changePassPopoverShow"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn outlined small color="blue" v-bind="attrs" v-on="on">
                        <v-icon left x-small>fas fa-sync</v-icon>Đổi
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title>Nhập mật khẩu mới</v-card-title>

                      <v-card-subtitle>Bạn không thể hoàn tác, sau khi đã thay đổi!!!</v-card-subtitle>
                        <v-text-field hide-details="" style="margin:10px 20px 0px 20px" dense=""
            label="Mật khẩu cũ" v-model="oldPassword"
            outlined
          ></v-text-field>
          <v-text-field hide-details="" style="margin:10px 20px 0px 20px" dense=""
            label="Mật khẩu mới" v-model="newPassword"
            outlined
          ></v-text-field>
          <v-text-field hide-details="" style="margin:10px 20px 0px 20px" dense=""
            label=" Nhập lại mật khẩu mới" v-model="newPasswordConfirm"
            outlined
          ></v-text-field>
                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn text @click="changePassPopoverShow = false">Hủy</v-btn>
                        <v-btn color="primary" text @click="changePasswordClick()">Đổi mật khẩu</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
              <v-text-field dense v-model="uInfo.user_phone" label="Số điện thoại" outlined></v-text-field>
              <v-text-field
                type="date"
                dense
                v-model="uInfo.user_birthday"
                label="Ngày sinh"
                outlined
              ></v-text-field>
              <v-text-field
                dense
                type="date"
                :readonly="!isAdmin"
                label="Ngày bắt đầu làm việc"
                v-model="uInfo.user_work_start"
                outlined
              ></v-text-field>
              <v-text-field
                dense
                type="date"
                :readonly="!isAdmin"
                label="Ngày kết thúc làm việc"
                v-model="uInfo.user_work_end"
                outlined
              ></v-text-field>
              <v-text-field dense label="CMND" v-model="uInfo.user_cmnd" outlined></v-text-field>
              <v-textarea
                rows="2"
                dense
                v-model="uInfo.user_other_info"
                label="Thông tin khác"
                outlined
              ></v-textarea>
            </v-form>
          </div>

          <v-divider></v-divider>
          <v-card-actions>
            <v-row align="center" justify="end">
              <v-btn
                style="
    margin-right: 25px;
"
                depressed
                color="success"
                :loading="isLoading"
                @click="save()"
              >Lưu lại</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <ImageViewer ref="viewer" />
  </div>
</template>
<script>
import { getUserInfo, updateUserInfo,resetPassword,changePassword } from "@/api/users";
import { getAllStore } from "@/api/stores";
import { upload } from "@/api/files";
import { UserRoleMap } from "@/utils/mapping";
import { BASE_API } from "@/utils/base";
import { showToast } from "@/utils";
import ImageViewer from "@/views/partials/widgets/ImageViewer.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {GetRole} from '@/utils/auth'
export default {
  components: {
    ImageViewer
  },
  data() {
    return {
      profileFilesSelected: null,
      avatarFileSelected: null,
      changePassPopoverShow:false,
      newPassword:null,
      oldPassword:null,
      newPasswordConfirm:null,
      isLoading: false,
      uId: null,
      stores: [],
      uInfo: {
        id: null,
        user_avatar: null,
        user_birthday: null,
        user_cmnd: null,
        user_profile_imgs: null,
        created_at: null,
        user_email: null,
        user_fullname: null,
        user_id: null,
        user_login: null,
        user_other_info: null,
        user_phone: null,
        user_role: 0,
        user_status: 0,
        user_work_end: null,
        user_work_start: null,
        stores: []
      }
    };
  },

  methods: {
    viewImage() {
      var arrLst = [];
      this.uInfo.user_profile_imgs.forEach(img => {
        arrLst.push({
          thumbnail: this.fileUrl(img),
          source: this.fileUrl(img)
        });
      });

      this.$refs.viewer.show(arrLst, 0);
    },
    fileUrl(name) {
      if (name) {
        return BASE_API + "files/get/" + name;
      } else {
        return "/assets/user-icon.png";
      }
    },
    resetPasswordClick(){
        this.isLoading = true;
        resetPassword({
            id:this.uId,
            password:this.newPassword
        }).then(()=>{
            this.changePassPopoverShow = false;
            this.isLoading = false;
            showToast(this,'success','Đã đổi mật khẩu');
        }).catch(()=>{
            this.isLoading = false;
        })
    },
    changePasswordClick(){
this.isLoading = true;
        changePassword({
            old_password:this.oldPassword,
            new_password:this.newPassword,
            new_password_confirm:this.newPasswordConfirm
        }).then(()=>{
            this.changePassPopoverShow = false;
            this.isLoading = false;
            showToast(this,'success','Đã đổi mật khẩu');
        }).catch(()=>{
            this.isLoading = false;
        })
    },
    changeAvtClick() {
      this.$refs[
        "file-avatar-input"
      ].$el.childNodes[1].childNodes[0].childNodes[0].childNodes[1].click();
    },
    addProfileFileClick() {
      this.$refs[
        "file-profile-input"
      ].$el.childNodes[1].childNodes[0].childNodes[0].childNodes[1].click();
    },
    async save() {
      this.isLoading = true;
      if (this.avatarFileSelected) {
        var formData = new FormData();

        formData.append("img", this.avatarFileSelected);

        var res1 = await upload(formData);
        this.uInfo.user_avatar = res1.data["img"];
        this.avatarFileSelected = null;
      }
      if (this.profileFilesSelected) {
        var formData2 = new FormData();
        this.profileFilesSelected.forEach((file, index) => {
          formData2.append(index, file);
        });
        var res2 = await upload(formData2);
        this.profileFilesSelected = null;

        if (typeof this.uInfo.user_profile_imgs != "object") {
          this.uInfo.user_profile_imgs = [];
        }
        res2.data.forEach(e => {
          this.uInfo.user_profile_imgs.push(e);
        });
      }
      updateUserInfo({ id: this.uId, info: this.uInfo }).then(() => {
        this.isLoading = false;
        showToast(this, "success", "Đã lưu");
      }).catch(()=>{
        this.isLoading = false;
      });
    },
    addStoreToUser(store) {
      let exist = false;
      this.uInfo.stores.forEach(s => {
        if (s.id == store.id) {
          exist = true;
        }
      });
      if (!exist) {
        this.uInfo.stores.push(store);
      }
    },
    fetchData() {
      this.isLoading = true;
      getUserInfo({ id: this.uId }).then(res => {
        this.isLoading = false;
        this.uInfo = res.data;
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Quản lý tài khoản", route: "/admin/users" },
          { title: this.uInfo.user_fullname, route: "1" }
        ]);
      });
    }
  },
  computed: {
    isAdmin(){
      var role = GetRole();
      return role==9;
    },
    userRoleMap() {
      return UserRoleMap;
    }
  },
  created() {
    this.uId = this.$route.params.id;
    getAllStore().then(res => {
      this.stores = res.data;
    });
    this.fetchData();
  }
};
</script>
<style lang="css">
.menu-item {
  cursor: pointer;
}
</style>