import request from '@/utils/request'
export function getAllStore(data) {
    return request({
      url: 'stores/all',
      method: 'post',
      data: data
    })
  }
  export function createNewStore(data) {
    return request({
      url: 'stores/new',
      method: 'post',
      data: data
    })
  }
  export function getStoreInfo(data) {
    return request({
      url: 'stores/get-info',
      method: 'post',
      data: data
    })
  }
  export function deleteStore(data) {
    return request({
      url: 'stores/delete',
      method: 'post',
      data: data
    })
  }
  export function editStore(data) {
    return request({
      url: 'stores/edit',
      method: 'post',
      data: data
    })
  }
  export function getUsersOfStore(data) {
    return request({
      url: 'stores/get-users',
      method: 'post',
      data: data
    })
  }

  export function findStoreByLocation(data) {
    return request({
      url: 'stores/find-by-location',
      method: 'post',
      data: data
    })
  }

  export function getStoreIManage(data) {
    return request({
      url: 'stores/i-manage',
      method: 'post',
      data: data
    })
  }

  